import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar } from '@mui/material';
// config
import { HEADER } from '../../../config-global';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import { NavSectionHorizontal } from '../../../components/nav-section'; //
import navConfig from './config-navigation';
import { useAuthContext } from '../../../auth/useAuthContext';
import { filterNavConfigByRole } from '../../../utils/navConfig';
function NavHorizontal() {
    const theme = useTheme();
    const { user } = useAuthContext();
    const filteredNavConfig = filterNavConfigByRole(navConfig, user?.roles[0].codes, user?.roles);
    return (_jsxs(AppBar, { component: "nav", color: "transparent", sx: {
            boxShadow: 0,
            top: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
        }, children: [_jsx(Toolbar, { sx: {
                    ...bgBlur({
                        color: theme.palette.background.default,
                    }),
                }, children: filteredNavConfig && _jsx(NavSectionHorizontal, { data: filteredNavConfig }) }), _jsx(Shadow, {})] }));
}
export default memo(NavHorizontal);
// ----------------------------------------------------------------------
function Shadow({ sx, ...other }) {
    return (_jsx(Box, { sx: {
            left: 0,
            right: 0,
            bottom: 0,
            height: 24,
            zIndex: -1,
            width: 1,
            m: 'auto',
            borderRadius: '50%',
            position: 'absolute',
            boxShadow: (theme) => theme.customShadows.z8,
            ...sx,
        }, ...other }));
}
